import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";

// layouts
import DashboardLayout from "../layouts/dashboard";

// config
import { DEFAULT_PATH } from "../config";
import LoadingScreen from "../components/LoadingScreen";
import MainLayout from "../layouts/main";


const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {

  const user = useSelector((state) => state.auth.user);

  return useRoutes([

    {
      path: "/auth",
      element: !user ? <MainLayout /> : <Navigate to="/homePage" replace />,
      children: [
        {element:<LoginPage/>, path:"login"},
        {element:<RegisterPage/>, path:"register"},
      ],
    },
    {
      path: "/landing-page",
      element: !user ? <LandingPage /> : <Navigate to="/homePage" replace />,

    },
    {
      path: "//riot.txt",
      element: <Navigate to="/riot.txt" replace />,

    },
    {
      path: "/",
      //element: user ? <DashboardLayout /> : <Navigate to="/landing-page" replace />,
      element: user ? <DashboardLayout /> : <Navigate to="/auth/login"/>,
      children: [
        {element: <Navigate to={DEFAULT_PATH} replace />, index: true },
        {path: "app", element: <GeneralApp /> },
        {path: "searchUsers", element : <SearchUsers/>},
        {path: "groupChats", element : <GroupChats/>},
        {path: "profilePage", element : <ProfilePage/>},
        {path: "otherUserprofilePage", element : <OtherUserProfilePage/>},
        {path: "homePage", element : <HomePage/>},
        {path: "notifications", element : <NotificationPage/>},
        {path: "calendar", element : <CalendarPage/>},
        {path: "steam/callback", element : <SteamCallback/>},
        //{ path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}

const LoginPage = Loadable(
  lazy(() => import("../pages/auth/Login")),
);
const RegisterPage = Loadable(
  lazy(() => import("../pages/auth/Register")),
);

const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp")),
);

const HomePage = Loadable(
  lazy(() => import("../pages/dashboard/Feed")),
);

const SearchUsers = Loadable(
  lazy(() => import("../pages/dashboard/searchUsers")),
);

const GroupChats = Loadable(
  lazy(() => import("../pages/dashboard/GeneralGroupApp")),
);

const ProfilePage = Loadable(
  lazy(() => import("../pages/dashboard/ProfilePage")),
);

const OtherUserProfilePage = Loadable(
  lazy(() => import("../pages/OtherUserProfile")),
);

const NotificationPage = Loadable(
  lazy(() => import("../pages/dashboard/Notifications")),
);

const LandingPage = Loadable(
  lazy(() => import("../pages/landingPage/Design")),

)

const SteamCallback = Loadable(
  lazy(() => import("../components/SteamConnect/SteamCallback")),

)

const CalendarPage = Loadable(
  lazy(() => import("../pages/dashboard/CalendarPage")),
)

// const Page404 = Loadable(lazy(() => import("../pages/Page404")));
