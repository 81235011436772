import {
  Avatar,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import logo from "../../assets/Images/logo1.png";
import { Nav_Buttons } from "../../data";
import LogoutButton from "../../components/logoutButton";
import ProfileView from "../../components/ProfileView";
import useSettings from "../../hooks/useSettings";
import { useSelector } from "../../redux/store";
import useResponsive from "../../hooks/useResponsive";

const DashboardLayout = () => {
  const theme = useTheme();
  const isMobile = useResponsive("between", "md", "xs", "sm");
  const [selected, setSelected] = useState(4); // Active icon state
  const { onToggleMode } = useSettings();
  const navigate = useNavigate();

  const name = useSelector((state) => state.auth.name);
  const username = useSelector((state) => state.auth.userName);
  const image_url = useSelector((state) => state.auth.imgUrl);

  const [showProfile, setShowProfile] = useState(false);

  const toggleProfile = () => {
    setShowProfile(!showProfile);
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh", // Full viewport height
        backgroundColor: theme.palette.background.default, // Page background color
        padding: "20px", // Padding from all sides for the gap effect
        boxSizing: "border-box", // Ensure padding doesn't shrink the box
      }}
    >
      {/* Sidebar */}
      <Box
        p={2}
        sx={{
          backgroundColor: theme.palette.background.navigation,
          boxShadow: "0 0px 2px rgba(0, 0, 0, 0.25)",
          height: "100%", // Ensure sidebar fills the available height
          width: 260,
          borderRadius: "20px", // Smooth corners
          border: "1px solid",
          borderColor: theme.palette.divider,
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="start"
          sx={{ height: "100%" }}
          spacing={3}
        >
          {/* Logo Section */}
          <Stack spacing={4}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.navigation,
                  height: 64,
                  width: 64,
                  borderRadius: 1.5,
                }}
              >
                <img src={logo} alt="chat-app-logo" />
              </Box>
              <Typography fontSize={32}>HOG-GG</Typography>
            </Stack>

            {/* Navigation Buttons */}
            <Stack
              spacing={2}
              direction="column"
              alignItems="flex-start"
              marginLeft={4}
            >
              {Nav_Buttons.map((el) =>
                el.index === selected ? (
                  <Box
                    key={el.index}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 1.5,
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1}
                      width={"200px"}
                      sx={{cursor: 'pointer'}}
                    >
                      <IconButton
                        sx={{ width: "max-content", color: "#fff" }}
                        onClick={() => setSelected(el.index)}
                      >
                        {el.icon}
                      </IconButton>
                      <Typography>{el.text}</Typography>
                    </Stack>
                  </Box>
                ) : (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                    width={"200px"}
                    onClick={() => {
                      setSelected(el.index);
                      navigate(el.route);
                    }}
                    sx={{cursor: 'pointer'}}
                  >
                    <IconButton
                      key={el.index}
                      sx={{
                        width: "max-content",
                        color:
                          theme.palette.mode === "light"
                            ? "#000"
                            : theme.palette.text.primary,
                      }}
                    >
                      {el.icon}
                    </IconButton>
                    <Typography>{el.text}</Typography>
                  </Stack>
                )
              )}
            </Stack>
          </Stack>

          {/* Bottom Section */}
          <Stack spacing={4} alignItems="start" width="100%">
            <Divider sx={{ width: "100%" }} />

            {/* Logout Section */}
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>Logout</Typography>
              <LogoutButton />
            </Stack>

            {/* Profile Section */}
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box
                style={{
                  position: "fixed",
                  display: showProfile ? "inline" : "none",
                  bottom: 61,
                  left: 70,
                }}
              >
                <ProfileView onClose={toggleProfile} />
              </Box>

              <Avatar
                src={`${image_url}/img_medium.webp`}
                sx={{ width: 42, height: 42, cursor: "pointer" }}
                onClick={toggleProfile}
              />

              <Stack direction="column" alignItems="flex-start">
                <Typography variant="subtitle2">{name}</Typography>
                <Typography variant="caption">{username}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>

      {/* Content Area */}
      <Box sx={{ flex: 1, overflow: "hidden", marginLeft: 2 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardLayout;
