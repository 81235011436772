import { useEffect } from "react";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ThemeSettings from "./components/settings";
import { getWebSocketTicket, webSocketService } from "./webSocket/websocket";
import { store } from "./redux/store";
//import { registerWebSocketHandlers } from "./webSocket/wsEventHandler";
import WebSocketHandlerComponent from "./webSocket/wsEventHandler";
import GlobalSnackbar from "./components/GlobalSnackbar";
import ConnectionStatusBar from "./components/ConnectionStatusBar";
import CallWebSocketProvider from "./components/CallScript/CallWebSocketContext";

function App() {
  const startWebSocketConnection = async () => {
    const storedTicket = getWebSocketTicket();
    const user = store.getState().auth.user;
    //console.log(user);
    if (false && user && storedTicket) {
      console.log("Reconnecting with stored ticket:", storedTicket);
      webSocketService.connect(storedTicket);
    } else if (user) {
      console.log("No stored ticket found.");
      const response = await webSocketService.prepare();
      if (response && response.ticket) {
        webSocketService.connect(response.ticket);
      } else {
        console.error("Failed to retrieve ticket from response");
      }
    }
  };

  useEffect(() => {
    // This code runs after the component mounts and after every update.
    console.log("app use Effect");
    startWebSocketConnection();
    // Cleanup function to run when the component unmounts or before the next effect runs
    //registerWebSocketHandlers();
    return () => {
      console.log("Cleanup");
    };
  });

  return (
    <ThemeProvider>
      <ThemeSettings>
      <CallWebSocketProvider>
        {/* <ConnectionStatusBar/> */}
        <GlobalSnackbar />
        <WebSocketHandlerComponent />
        <Router />{" "}
        </CallWebSocketProvider>
      </ThemeSettings>
    </ThemeProvider>
  );
}

export default App;
