const AppName = "GameBuddy";


// Debug
// const WsUrl = "ws://localhost:8080/connect";
// const BASE_API_URL = "http://localhost:8080/api/v1";
// const BASE_MEDIA_API_URL = "http://localhost:8082";

// PROD
const WsUrl = process.env.WS_CONNECT_URL;
const BASE_API_URL = process.env.GC_URL;
const BASE_MEDIA_API_URL = process.env.GM_URL;
const SOCKET_IO_URL = process.env.SOCKET_IO_URL;

// DEV
// const WsUrl = "ws://localhost/gc/websocket/connect";
// const BASE_API_URL = "http://localhost/gc/api/v1";
// const BASE_MEDIA_API_URL = "http://localhost/gm";

//  const WsUrl = "wss://hog-gg.com/gc/websocket/connect"
//  const BASE_API_URL = "https://hog-gg.com/gc/api/v1";
//  const BASE_MEDIA_API_URL = "https://hog-gg.com/gm";
  //const SOCKET_IO_URL = "wss://hog-gg.com";


export {AppName, WsUrl,BASE_API_URL , BASE_MEDIA_API_URL, SOCKET_IO_URL}